<template>
  <v-container fluid>
    <div class="text-center">
      <v-row>
        <v-col>
          <v-card max-width="50%" class="mx-auto my-12">
            <v-card-title> Dados </v-card-title>
            <table class="mt-2 mt-xl-0 mx-auto w-100">
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold"
                    ><font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit"
                        >Oportunidade</font
                      ></font
                    ></span
                  >
                </th>
                <td class="pb-50">
                  <font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">
                      {{ data.oportunidade }}
                    </font></font
                  >
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold"
                    ><font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit">Nome</font></font
                    ></span
                  >
                </th>
                <td class="pb-50">
                  <font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">
                      {{ data.nome }}
                    </font></font
                  >
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold"
                    ><font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit">Cargo</font></font
                    ></span
                  >
                </th>
                <td class="pb-50">
                  <font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">
                      {{ data.cargo }}
                    </font></font
                  >
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold"
                    ><font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit">E-mail</font></font
                    ></span
                  >
                </th>
                <td class="pb-50 text-capitalize">
                  <font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">
                      {{ data.email }}
                    </font></font
                  >
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold"
                    ><font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit">Status</font></font
                    ></span
                  >
                </th>
                <td class="pb-50 text-capitalize">
                  <font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">
                      {{ data.status }}
                    </font></font
                  >
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold"
                    ><font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit"
                        >Data de Retorno</font
                      ></font
                    ></span
                  >
                </th>
                <td class="pb-50">
                  <font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">
                      {{ data.data_retorno }}
                    </font></font
                  >
                </td>
              </tr>
            </table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ComercialService from "@/services/Comercial/ComercialService";
import ToastService from "@/services/ToastService";
export default {
  data: () => ({
    loading: false,
    data: {},
    ComercialService: new ComercialService(""),
  }),

  mounted: async function () {
    const id = this.$route.params.id;

    this.loading = true;
    await this.showData(id);
  },

  methods: {
    async showData(id) {
      try {
        this.data = await this.ComercialService.show("comercial/" + id);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
</style>